// see https://stackoverflow.com/a/1373724
// eslint-disable-next-line max-len
export const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
// eslint-disable-next-line max-len
export const emailOnlyRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export default function validateEmail(email: string, blackList: string[] = []): string | boolean {
  // eslint-disable-next-line no-param-reassign
  email = String(email);
  if (email.length === 0) {
    return 'Please fill out this field';
  }
  if (!emailOnlyRegExp.test(email.toLowerCase())) {
    return 'Please input valid email address';
  }
  const emailDomainArray = email.split('@')[1].split('.');
  const emailDomain = emailDomainArray[emailDomainArray.length - 2].concat('.', emailDomainArray[emailDomainArray.length - 1]);
  if (blackList.includes(emailDomain) || blackList.includes(emailDomainArray[emailDomainArray.length - 3]?.concat('.', emailDomain))) {
    let extraMessage = '';
    if (blackList.includes('gmail.com')) {
      extraMessage = ' (no gmail.com, qq.com, etc. allowed)';
    }
    if (blackList.includes('temp-mail.org')) {
      extraMessage = ' (no temporary email allowed)';
    }
    return `Please provide business email address${extraMessage}`;
  }
  return true;
}
