import React from 'react';
import BasicLink from './BasicLink';
// import { Link } from 'gatsby';

import './LinkWithIcon.scss';

export interface LinkWithIconProps {
  to: string;
  icon: SVGIconType,
  children: React.ReactNode;
  className?: string;
  fontSize?: 'sm' | 'normal' | 'lg';
  target?: string;
}

export default function LinkWithIcon(
  {
    to,
    icon: Icon,
    children,
    className = '',
    fontSize = 'normal',
    target,
  }: LinkWithIconProps,
): JSX.Element {
  return (
    <BasicLink to={to} target={target} className={`link-with-icon font-size-${fontSize} ${className}`} style={{ userSelect: 'none' }}>
      <Icon /><span>{children}</span>
    </BasicLink>
  );
}
