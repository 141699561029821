/* eslint-disable max-len */
import React from 'react';
import axios, { AxiosError } from 'axios';

import AOS from './AOS';
import RequestFormSection, { ExtendedFormDataType, RequestFormError, RequestFormSectionProps } from './RequestFormSection';

import { FormItem } from '../../cloud-functions/shared/utils/Form';
import ContactUsForm, { ContactUsFormFieldsKeysType, CategoryType } from '../../cloud-functions/shared/utils/ContactUsForm';

import EmailIcon from '../img/icons/email.svg';

import './ContactUsFormPage.scss';

export interface ContactUsFormPageProps {
  color: string;
  hasAttachments?: boolean;
  notificationCheck?: boolean;
  category: CategoryType;
}

export default function ContactUsFormPage(
  {
    color = 'primary',
    hasAttachments = false,
    notificationCheck = false,
    category,
  }: ContactUsFormPageProps,
): JSX.Element {
  const fields: Array<FormItem> = [
    {
      name: 'FIRST_NAME',
      required: true,
    },
    {
      name: 'LAST_NAME',
      required: true,
    },
    {
      name: 'EMAIL',
      required: true,
    },
    {
      name: 'QUESTION',
      required: true,
    },
  ];

  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);

  const fieldsRequirements = fields.reduce<Partial<Record<ContactUsFormFieldsKeysType, boolean>>>((result, value) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result[value.name] = value.required;
    return result;
  }, {});

  const onSubmit: RequestFormSectionProps['onSubmit'] = async (contactUsFormData: ExtendedFormDataType) => {
    const contactUsForm = new ContactUsForm(contactUsFormData);
    const validationResult = contactUsForm.validate(fieldsRequirements);
    if (typeof validationResult !== 'boolean') {
      const invalidFields = validationResult as unknown as Record<string, string>;
      return Promise.reject(new RequestFormError(invalidFields, ''));
    }

    const formData = new FormData();
    Object.entries(contactUsForm.data).forEach(([key, value]) => {
      if (key !== 'attachments') {
        formData.append(key, value || '');
      }
      else if (value) {
        const files = value as unknown as File[];
        files.forEach((file) => {
          formData.append(file.name, file, file.name);
        });
      }
    });

    try {
      await axios({
        method: 'post',
        url: `${process.env.GATSBY_API_URL}/contact-us`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setFormSubmitted(true);
    }
    catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (axiosError.response.status >= 500) {
          throw new RequestFormError(undefined, 'Internal error happened. Please report to support@cadexchanger.com');
        }
        const body = axiosError.response.data || {};
        const { message } = body;
        if (message) {
          delete body.message;
        }
        throw new RequestFormError(body, message);
      } if (axiosError.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        throw new RequestFormError(undefined, `${axiosError.message} happened. Please try again.`);
      }
      // Something happened in setting up the request that triggered an Error
      // eslint-disable-next-line no-console
      console.log('Error', axiosError.message);
      throw new RequestFormError(undefined, 'Internal error happened. Please try again.');
    }
    return undefined;
  };
  return (
    <AOS>
      <section className="pt-md-6 mt-md-6 pb-6 pb-md-7">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 col-xxl-6 mx-auto">
              <h3 className=" h2 text-center">Contact us</h3>
            </div>
          </div>
        </div>
      </section>

      {formSubmitted ? (
        <section className="pb-8 pb-md-10">
          <div className="container">
            <div className="row justify-content-center">
              <EmailIcon />
            </div>

            <div className="row align-items-center mt-6 flex-column">
              <p className="text-center">
                Thank you for interest in CDX GROUP!
              </p>
              <p className="text-center">
                We got your inquiry and will return with a (human) response <br /> as soon as possible.
              </p>
            </div>
          </div>
        </section>
      ) : (
        <RequestFormSection
          className="pb-8 pb-md-10"
          fields={fields}
          color={color}
          suggestCall={false}
          onSubmit={onSubmit}
          hasAttachments={hasAttachments}
          notificationCheck={notificationCheck}
          category={category}
        />
      )}
    </AOS>
  );
}
