import { enumKeys, Form, FormLabels } from './Form';

enum ContactUsFormFields {
  EMAIL,
  FULL_NAME,
  PHONE,
  COUNTRY,
  PRODUCT,
  PREFERRED_LANGUAGE,
  POSITION,
  PLATFORM,
  QUESTION,
  VERSION,
  CATEGORY,
  SUBSCRIBE,
}

export type CategoryType = 'technical' | 'advice' | 'licensing' | 'general' | 'feedback';

export type ContactUsFormFieldsKeysType = keyof typeof ContactUsFormFields;

export const positions: string[] = [
  'Designer/Engineer',
  'Developer',
  'Product manager',
  'Executive manager',
  'Educator/Student',
  'Other',
];

export const products: string[] = [
  'CAD Exchanger',
  'CAD Exchanger SDK',
  'Not sure',
];

/* export const platforms: string[] = [
  'Windows',
  'macOS',
  'Linux',
]; */

export const versions: string[] = [
  '3.14.0',
  '3.12.0',
  '3.11.0',
  '3.10.2',
  'Older',
];

export default class ContactUsForm extends Form<ContactUsFormFieldsKeysType> {
  getHtml(): string {
    return Object.entries(this.data).reduce<string>((result, [key, value]) => {
      const label = FormLabels[key as unknown as ContactUsFormFieldsKeysType];

      if (key === 'QUESTION' && value) {
        return `${result}<p>Question: ${value.replace(/\n/g, '<br>')}</p>\n`;
      }
      return `${result}<p>${label}: ${value}</p>\n`;
    }, '');
  }

  static get fileSizeLimitMB(): number {
    return 50;
  }

  static get fileSizeLimit(): number {
    return ContactUsForm.fileSizeLimitMB * 1024 * 1024;
  }

  static get maxAttachmentsCount(): number {
    return 5;
  }

  toString(): string {
    return `ContactUsForm:
${enumKeys(ContactUsFormFields).map((key) => `${key}=${this.data[key]}`).join('\n')}`;
  }
}
