import React from 'react';
import { Link } from 'gatsby';

export interface BasicLinkProps {
  to: string;
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  target?: string;
  style?: React.CSSProperties;
  state?: Record<string, string>
}

export default function BasicLink(
  {
    to,
    className,
    children,
    onClick,
    target,
    style,
    state,
  }: BasicLinkProps,
): JSX.Element {
  if (to.startsWith('/')) {
    return (
      <Link to={to} onClick={onClick} state={state} className={className} style={style}>
        {children}
      </Link>
    );
  }
  return (
    <a href={to} target={target} onClick={onClick} className={className} style={style}>
      {children}
    </a>
  );
}
