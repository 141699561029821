import { graphql, useStaticQuery } from 'gatsby';
import { Helmet, HelmetProps } from 'react-helmet';
import React from 'react';

type SEOMetaType = HelmetProps['meta'];

export interface SEOProps {
  lang?: string;
  description?: string;
  meta?: SEOMetaType;
  keywords?: string[];
  tags?: string[];
  title: string;
  children?: React.ReactNode;
  url?: string;
  name?: string;
  image?: string;
  locale?: string;
  ogType?: 'article' | 'website'
  noTitleTail?: boolean;
  publishedTime?: string;
  modifiedTime?: string;
  articleSection?: string;
  articleAuthor?: string;
}

interface SiteMetadata {
  title: string | undefined;
  description: string | undefined;
  author: string | undefined;
  siteUrl: string | undefined;
  siteName: string | undefined;
  locale: string | undefined;
}

function SEO(
  { lang = 'en',
    description = '',
    meta = [],
    keywords = [],
    tags = [],
    title,
    url,
    name,
    image,
    locale,
    modifiedTime,
    publishedTime,
    ogType = 'website',
    noTitleTail,
    articleSection,
    articleAuthor,
    children }: SEOProps,
): JSX.Element {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteName
            locale
          }
        }
      }
    `,
  );

  const siteMetadata = site.siteMetadata as SiteMetadata;

  const metaDescription = description || siteMetadata.description;
  const metaName = name || siteMetadata.siteName;
  const metaLocale = locale || siteMetadata.locale;

  const metaItems: SEOMetaType = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: noTitleTail ? `${title}` : `${title} | ${siteMetadata.title}`,
    },
    {
      property: 'og:type',
      content: ogType,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteMetadata.author,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      property: 'og:site_name',
      content: metaName,
    },
    {
      property: 'og:locale',
      content: metaLocale,
    },
  ];

  if (publishedTime) {
    metaItems.push({
      property: 'article:published_time',
      content: publishedTime,
    });
  }

  if (modifiedTime || publishedTime) {
    metaItems.push({
      property: 'article:modified_time',
      content: modifiedTime ?? publishedTime,
    });
  }

  if (articleSection) {
    metaItems.push({
      property: 'article:section',
      content: articleSection,
    });
  }

  if (articleAuthor) {
    metaItems.push({
      property: 'article:author',
      content: articleAuthor,
    });
  }

  if (image) {
    const imageLink = image.startsWith('/') ? `${siteMetadata.siteUrl}${image}` : image;
    metaItems.push(
      {
        property: 'og:image',
        content: imageLink,
      },
      {
        name: 'twitter:image',
        content: imageLink,
      },
    );
  }

  if (url) {
    metaItems.push({
      property: 'og:url',
      content: url,
    });
  }
  // blog-post sometimes sends null
  if (tags?.length > 0) {
    tags.forEach((tag) => {
      metaItems.push({
        property: 'article:tag',
        content: tag,
      });
    });
  }

  if (keywords.length > 0) {
    metaItems.push({
      name: 'keywords',
      content: keywords.join(', '),
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={noTitleTail ? '%s' : `%s | ${siteMetadata.title}`}
      meta={metaItems.concat(meta)}
    >
      {children}
    </Helmet>
  );
}

export default SEO;
