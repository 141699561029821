/* eslint-disable import/no-extraneous-dependencies */
import { isValidPhoneNumber } from 'libphonenumber-js';
import validateEmail from './validateEmail';

/* eslint-disable max-classes-per-file */
export enum FormFields {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  FULL_NAME,
  PHONE,
  COUNTRY,
  ADDRESS,
  PRODUCT,
  PREFERRED_LANGUAGE,
  COMPANY,
  COMPANY_ADDRESS,
  COMPANY_SIZE,
  POSITION,
  PLATFORM,
  VAT_ID,
  // INDUSTRY,
  USE_CASE,
  QUESTION,
  FORMAT,
  VERSION,
  CATEGORY,
  SUBSCRIBE,
  SDK_USE_CASE,

  QUOTE_NUMBER,
  FORMATS,
  ADDONS,
  OSES,
  DEVELOPERS,
  SUPPORT,
  APPLICATION_NAME,
  APPLICATION_DESCRIPTION,
  APPLICATION_SITE,

  MAINTENANCE,
  LICENSE_SIGNER,
  LICENSE_DURATION,
  LICENSE_NUMBER,
  LICENSE_TYPE,
  LINK,

  LICENSOR,
  LANGUAGE,
  DISCOUNT_PLAN,
  DISCOUNT,
  QUOTE_DATE,
  QUOTE_PERIOD,
  TERMS_PERIOD,
}

export type FormFieldsKeysType = keyof typeof FormFields;

export function enumKeys<E extends Record<string, unknown>>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

export type FormFieldsTypes = 'email' | 'text' | 'textarea' | 'select' | 'file';

export const FormItemType: Record<FormFieldsKeysType, FormFieldsTypes> = {
  EMAIL: 'email',
  FIRST_NAME: 'text',
  LAST_NAME: 'text',
  FULL_NAME: 'text',
  PHONE: 'text',
  COUNTRY: 'text',
  ADDRESS: 'text',

  PRODUCT: 'select',
  PREFERRED_LANGUAGE: 'select',

  COMPANY: 'text',
  COMPANY_ADDRESS: 'text',
  COMPANY_SIZE: 'select',
  POSITION: 'select',
  PLATFORM: 'select',
  VAT_ID: 'text',
  // INDUSTRY: 'select',

  APPLICATION_NAME: 'textarea',
  APPLICATION_DESCRIPTION: 'textarea',
  APPLICATION_SITE: 'textarea',

  USE_CASE: 'textarea',
  QUESTION: 'textarea',
  FORMAT: 'select',
  VERSION: 'select',
  CATEGORY: 'text',
  SUBSCRIBE: 'text',
  SDK_USE_CASE: 'textarea',

  QUOTE_NUMBER: 'text',
  FORMATS: 'text',
  ADDONS: 'select',
  OSES: 'select',
  DEVELOPERS: 'text',
  SUPPORT: 'select',
  MAINTENANCE: 'text',
  LICENSE_DURATION: 'select',
  LICENSE_NUMBER: 'text',
  LICENSE_TYPE: 'select',
  LICENSE_SIGNER: 'textarea',

  LINK: 'text',

  LICENSOR: 'select',
  LANGUAGE: 'select',
  DISCOUNT_PLAN: 'select',
  DISCOUNT: 'select',
  QUOTE_DATE: 'text',
  QUOTE_PERIOD: 'text',
  TERMS_PERIOD: 'text',
};

export const FormLabels: Record<FormFieldsKeysType, string> = {
  EMAIL: 'Business email',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  FULL_NAME: 'Full name',
  PHONE: 'Phone',
  COUNTRY: 'Country',
  ADDRESS: 'Address',

  PRODUCT: 'Product',
  PREFERRED_LANGUAGE: 'Programming language',

  COMPANY: 'Company name',
  COMPANY_ADDRESS: 'Company address',
  COMPANY_SIZE: 'Company size',
  POSITION: 'Role',
  PLATFORM: 'Platform',
  VAT_ID: 'VAT ID',
  // INDUSTRY: 'Industry',

  APPLICATION_NAME: 'Application name',
  APPLICATION_DESCRIPTION: 'Application description',
  APPLICATION_SITE: 'Application site',

  USE_CASE: 'Use case',
  QUESTION: 'Your question',
  FORMAT: 'Format name',
  VERSION: 'CAD Exchanger version',
  CATEGORY: 'Category',
  SUBSCRIBE: 'Subscribe',
  SDK_USE_CASE: 'CAD Exchanger SDK intended usage description',

  QUOTE_NUMBER: 'Quote number',
  FORMATS: 'Formats',
  ADDONS: 'Add-ons',
  OSES: 'OSes',
  DEVELOPERS: 'Developers',
  SUPPORT: 'Support plan',
  MAINTENANCE: 'Maintenance',
  LICENSE_DURATION: 'License duration',
  LICENSE_NUMBER: 'Number of licenses',
  LICENSE_TYPE: 'License type',
  LICENSE_SIGNER: 'License agreement signer\'s email',

  LINK: 'Link',

  LICENSOR: 'Licensor',
  LANGUAGE: 'Language',
  DISCOUNT_PLAN: 'Discount plan',
  DISCOUNT: 'Discount',
  QUOTE_DATE: 'Quote date',
  QUOTE_PERIOD: 'Quote period',
  TERMS_PERIOD: 'Payment terms\' period',
};

export const FormPlaceholders: Record<FormFieldsKeysType, string> = {
  EMAIL: FormLabels.EMAIL,
  FIRST_NAME: FormLabels.FIRST_NAME,
  LAST_NAME: FormLabels.LAST_NAME,
  FULL_NAME: FormLabels.FULL_NAME,
  PHONE: FormLabels.PHONE,
  COUNTRY: FormLabels.COUNTRY,
  ADDRESS: FormLabels.ADDRESS,

  PRODUCT: FormLabels.PRODUCT,
  PREFERRED_LANGUAGE: FormLabels.PREFERRED_LANGUAGE,

  COMPANY: FormLabels.COMPANY,
  COMPANY_ADDRESS: 'Country, City, Street, Office',
  COMPANY_SIZE: FormLabels.COMPANY_SIZE,
  POSITION: FormLabels.POSITION,
  PLATFORM: FormLabels.PLATFORM,
  // INDUSTRY: FormLabels.INDUSTRY,
  VAT_ID: 'e.g. ATU99999999',

  APPLICATION_NAME: 'Must be brand name of the application, online service, hardware, etc. to unambiguously identify the application',
  APPLICATION_DESCRIPTION: 'Detailed description of the application\'s primary purpose',
  APPLICATION_SITE: '(URL or N/A if for internal use)',

  USE_CASE: 'Please describe how you plan to use CAD Exchanger.\nThis will let us tailor recommendations and help you start faster.',
  QUESTION: FormLabels.QUESTION,
  FORMAT: FormLabels.FORMAT,
  VERSION: FormLabels.VERSION,
  CATEGORY: FormLabels.CATEGORY,
  SUBSCRIBE: FormLabels.SUBSCRIBE,
  SDK_USE_CASE: 'Description of how CAD Exchanger SDK will be used',

  QUOTE_NUMBER: FormLabels.QUOTE_NUMBER,
  FORMATS: FormLabels.FORMATS,
  ADDONS: FormLabels.ADDONS,
  OSES: FormLabels.OSES,
  DEVELOPERS: FormLabels.DEVELOPERS,
  SUPPORT: FormLabels.SUPPORT,
  MAINTENANCE: FormLabels.MAINTENANCE,
  LICENSE_DURATION: FormLabels.LICENSE_DURATION,
  LICENSE_NUMBER: FormLabels.LICENSE_NUMBER,
  LICENSE_TYPE: FormLabels.LICENSE_TYPE,
  LICENSE_SIGNER: 'Email of the authorized person who will sign the license agreement',

  LINK: FormLabels.LINK,

  LICENSOR: FormLabels.LICENSOR,
  LANGUAGE: FormLabels.LANGUAGE,
  DISCOUNT_PLAN: FormLabels.DISCOUNT_PLAN,
  DISCOUNT: FormLabels.DISCOUNT,
  QUOTE_DATE: FormLabels.QUOTE_DATE,
  QUOTE_PERIOD: FormLabels.QUOTE_PERIOD,
  TERMS_PERIOD: FormLabels.TERMS_PERIOD,
};

export const companySizes: string[] = [
  '<6',
  '6-10',
  '11-50',
  '51-500',
  '>500',
];

export interface FormItem {
  // type: FormFieldsTypes;
  name: FormFieldsKeysType;
  // label: string;
  // placeholder?: string;
  required: boolean;
  options?: Array<{ value: string, label: string, hidden?: boolean }>
  defaultValue?: string;
  fullWidth?: boolean;
}

export type FormDataType = Partial<Record<FormFieldsKeysType, string>>;

// RegExp has been taken from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
// export const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
// RegExp has been taken from https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
export const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
export const htmlRegex = /(<\/?(.|\n)*>)/gi;

export const validateInputContainsHTML = (str: string): boolean => htmlRegex.test(str);
export const validateInputContainsUrl = (str: string): boolean => urlRegex.test(str);

export class Form<T extends FormFieldsKeysType> {
  data: Partial<Record<T, string>>;

  constructor(data: Partial<Record<T, string>>) {
    this.data = data;
  }

  validate(
    requirements: Partial<Record<T, boolean>>,
    emailBlackList: string[] = [],
  ): Partial<Record<T, string>> | true {
    const result: Partial<Record<T, string>> = {};

    enumKeys(requirements).forEach((key) => {
      if (requirements[key]) {
        switch (key) {
          case 'EMAIL':
          case 'LICENSE_SIGNER':
            this.checkEmail(key, emailBlackList, result);
            break;
          case 'FULL_NAME':
            this.checkFullName(result);
            break;
          default:
            this.checkField(key, result);
        }
      }
      const keyValue = String(this.data[key]);
      if (keyValue) {
        switch (key) {
          case 'PHONE':
            if (!isValidPhoneNumber(keyValue)) {
              result[key] = 'Please input correct phone number';
            }
            break;
          case 'APPLICATION_SITE':
            if (!validateInputContainsUrl(keyValue) && keyValue !== 'N/A') {
              result[key] = 'Please input correct URL or N/A';
            }
            break;
          case 'QUESTION':
            break;
          default:
            if (validateInputContainsHTML(keyValue) || validateInputContainsUrl(keyValue)) {
              result[key] = 'Field contains unacceptable data';
            }
        }
      }
    });
    return Object.keys(result).length > 0 ? result : true;
  }

  private checkField<P extends T>(key: P, result: Partial<Record<P, string>>) {
    if (!this.data[key]) {
      result[key] = 'Please fill out this field';
    }
  }

  private checkFullName(result: Partial<Record<'FULL_NAME', string>>) {
    const fullName = (this.data as Partial<Record<FormFieldsKeysType, string>>).FULL_NAME;

    if (fullName?.trim()) {
      const [, ...lastNameParts] = fullName.split(' ');
      const lastName = lastNameParts.join(' ');
      if (!lastName?.trim()) {
        result.FULL_NAME = 'Please fill out last name';
      }
    }
    else {
      result.FULL_NAME = 'Please fill out this field';
    }
  }

  private checkEmail(field: 'EMAIL' | 'LICENSE_SIGNER', emailBlackList: string[], result: Partial<Record<'EMAIL' | 'LICENSE_SIGNER', string>>) {
    if (field !== 'EMAIL' && field !== 'LICENSE_SIGNER') {
      return;
    }

    const email = (this.data as Partial<Record<FormFieldsKeysType, string>>)[field];

    if (email) {
      const aEmailValidation = validateEmail(email.toLowerCase(), emailBlackList);
      if (typeof aEmailValidation === 'string') {
        result[field] = aEmailValidation;
      }
    }
    else {
      result[field] = 'Please fill out this field';
    }
  }

  toHtml(): string {
    return Object.entries(this.data).reduce<string>((result, [key, value]) => {
      const label = FormLabels[key as unknown as FormFieldsKeysType];

      if ((key === 'QUESTION' || key === 'USE_CASE')) {
        return `${result}<p>Question: ${((value as string) || '').replace(/\n/g, '<br>')}</p>\n`;
      }
      return `${result}<p>${label}: ${value}</p>\n`;
    }, '');
  }

  toString(): string {
    return enumKeys(this.data).map((key) => `${key}=${this.data[key]}`).join('\n');
  }
}
