import React from 'react';

import SEO from 'cadex-gatsby-theme/src/components/SEO';

// import AOS from 'cadex-gatsby-theme/src/components/AOS';

import ContactUsFormPage from 'cadex-gatsby-theme/src/components/ContactUsFormPage';

import TopBgImage from './Image20221107151214.png';

import './index.scss';

export default function LandingPage(): JSX.Element {
  return (
    <>

      <SEO
        title="CDX GROUP - FZCO"
        noTitleTail
        description="CDX GROUP is an international IT company that offers software products, technologies and solutions in the area of CAD/CAM/CAE, BIM/AEC and PLM."
      />
      <div className="container">
        <div className="row justify-content-center">

          <div className="col-12 col-md-10 col-lg-10 text-center my-6">
            <h1>CDX GROUP </h1>

            <p className="h3 mb-5">CDX GROUP is an international IT company that offers software products, technologies and solutions in the area of digital manufacturing, CAD/CAM/CAE, BIM/AEC and PLM.</p>
            <p className="h3 mb-5">The company’s product portfolio features <a href="https://cadexchanger.com">CAD Exchanger</a>, a world-wide known software product family in the area of 3D data conversion, analysis and visualization, as well as <a href="https://manufacturing.cadexchanger.com">Manufacturing Toolkit</a>, a software technology for building MaaS (Manufacturing-as-a-Service) cloud platforms.</p>

          </div>
        </div>
      </div>
      <img src={TopBgImage} alt="" className="w-100" />

      <ContactUsFormPage
        color="blue"
        category="feedback"
      />
      <footer className="py-12 mt-12">
        <p className="mx-md-12 text-center mb-0">
          20820 - 001, Building A2 IFZA Business Park - Dubai Silicon Oasis - Dubai - United Arab Emirates
        </p>
      </footer>

    </>
  );
}
