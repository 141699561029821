import React from 'react';
import clsx from 'clsx';
import './GreenCheckboxWithLabel.scss';

interface GreenCheckboxWithLabel {
  children: React.ReactNode;
  id: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  big?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>,
}
export default function GreenCheckboxWithLabel({
  children,
  id,
  onChange,
  disabled,
  checked,
  big,
  className,
}: GreenCheckboxWithLabel): JSX.Element {
  return (
    <div className={clsx('green-checkbox custom-control custom-checkbox pb-0', className, big && 'big-check')}>
      <input type="checkbox" checked={checked} disabled={disabled} className="green-checkbox custom-control-input" value={id} onChange={onChange} id={id} />
      <label className={clsx('green-checkbox custom-control-label', !disabled && 'text-black')} htmlFor={id}>{children}</label>
    </div>
  );
}
