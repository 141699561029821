/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { Dropdown/* , Overlay, Popover */ } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import clsx from 'clsx';

import RequiredIcon from './RequiredIcon';
import ChevronDown from '../img/icons/chevron-down.svg';

import './FormControl.scss';

export interface FormControlProps {
  id: string;
  type?: 'email' | 'text' | 'textarea' | 'select' | 'file';
  placeholder?: string;
  label?: string;
  required?: boolean;
  name: string;
  className?: string;
  value: string;
  setValue(value: string): void;
  invalidMessage: boolean | string;
  hasAttachments?: boolean;
  children?: React.ReactNode;
  options?: Array<{ value: string, label: string, hidden?: boolean }>
}

export default function FormControl({
  id,
  type = 'text',
  label = '',
  placeholder = label,
  required = false,
  className = '',
  name,
  value,
  setValue,
  invalidMessage = false,
  hasAttachments = false,
  children = '',
  options,
}: FormControlProps): JSX.Element {
  let Tag: React.ElementType;
  let validChildren;
  switch (type) {
    case 'email':
    case 'file':
    case 'text':
      Tag = 'input';
      break;
    case 'textarea':
      Tag = 'textarea';
      break;
    case 'select':
      Tag = 'select';
      validChildren = children;
      break;
    default: throw new Error('Invalid control type');
  }

  const target = useRef(null);

  interface ChangeEvent {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }

  if (type === 'select') {
    if (!options || options.length === 0) {
      throw new Error(`Error: item "${name}" has 'select' type, but has no options!`);
    }
  }

  const handleSelect = (key: string | null) => {
    if (key) {
      setValue(key);
    }
  };

  const handlePhoneNumber = (phoneNumber: string): void => {
    if (phoneNumber === '') {
      setValue('');
    }
    else {
      setValue(`+${phoneNumber}`);
    }
  };

  return (
    <div className={`form-group ${className}`}>
      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && (
        <label htmlFor={id} className="lead text-black">
          {label} {required && <RequiredIcon />}
        </label>
      )}
      {name === 'PHONE' || id === 'PHONE' ? (
        <div ref={target}>
          <PhoneInput
            placeholder={placeholder}
            value={value}
            onChange={handlePhoneNumber}
            inputStyle={{ width: 'inherit' }}
            inputClass={`phone-number ${invalidMessage ? 'invalid-input' : ''}`}
            country="gb"
            prefix="+"
          />
        </div>
      ) : (type === 'select' ? (
        <Dropdown ref={target} onSelect={handleSelect} onClick={() => (invalidMessage ? !invalidMessage : invalidMessage)}>
          <Dropdown.Toggle
            className={clsx(
              'dropdown-toggle-styled',
              !!invalidMessage && 'invalid-input',
              value && 'has-selection',
            )}
          >
            <span className="display-inline-block">{value || (options && options[0].value === '' ? options[0].label : 'Select one')}</span>
            <ChevronDown className="" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-styled">
            {options && options.map((option) => (
              <Dropdown.Item
                eventKey={option.value}
                key={option.value}
                value={option.value}
                role="button"
                active={option.value === value}
                hidden={option?.hidden}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Tag
          id={id}
          type={type}
          required={required}
          ref={target}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e: ChangeEvent) => setValue(e.target.value)}
          className={clsx(
            'form-control',
            !!invalidMessage && 'invalid-input',
            value && 'has-selection',
            hasAttachments && type === 'textarea' && 'drop-area',
          )}
        >
          {validChildren}
        </Tag>
      ))}
      {invalidMessage && (
        <div className="invalid-feedback d-block mt-2">{invalidMessage}</div>
      )}
      {/* <Overlay target={target.current} show={!!invalidMessage} placement="bottom-end">
        <Popover id="invalid_email_message" className="with-border shadow">
          <Popover.Content className="px-4 py-3 d-flex align-items-center">
            <i className="fe fe-alert-circle h2 text-warning my-0 mr-4" />
            <div className="mr-auto">
              {invalidMessage}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay> */}
    </div>
  );
}
