/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import FormControl from './FormControl';
import RequiredIcon from './RequiredIcon';
import LinkWithIcon from './LinkWithIcon';

import { FormDataType, FormItem, FormItemType, FormLabels, FormPlaceholders } from '../../cloud-functions/shared/utils/Form';
import ContactUsForm from '../../cloud-functions/shared/utils/ContactUsForm';
import { NewsletterSubscribeFormFieldsKeysType } from '../../cloud-functions/shared/utils/NewsletterSubscribeForm';
import { emailRegExp } from '../../cloud-functions/shared/utils/validateEmail';

import PhoneIcon from '../img/icons/phone.svg';
import AttachmentIcon from '../img/icons/attachment.svg';
import InformationIcon from '../img/icons/information.svg';

import './RequestFormSection.scss';
import GreenCheckboxWithLabel from './GreenCheckboxWithLabel';

export class RequestFormError extends Error {
  invalidFields?: Record<string, string>;

  constructor(invalidFields?: Record<string, string>, message?: string) {
    super(message);
    this.invalidFields = invalidFields;
  }
}

export interface ExtendedFormDataType extends FormDataType {
  attachments?: File[];
  subscribe?: boolean;
}

export type EmailDataType = Partial<Record<NewsletterSubscribeFormFieldsKeysType, string>>;

export interface RequestFormSectionProps {
  fields: Array<FormItem>;
  color: string;
  onSubmit(formData: FormDataType): Promise<void>;
  className?: string;
  buttonText?: string;
  hasAttachments?: boolean;
  notificationCheck?: boolean;
  suggestCall?: boolean;
  ccLink?: boolean;
  category?: string;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export default function RequestFormSection(
  {
    fields,
    color,
    onSubmit,
    className = '',
    buttonText = 'Submit',
    hasAttachments = false,
    notificationCheck = false,
    suggestCall = false,
    ccLink,
    category,
  }: RequestFormSectionProps,
): JSX.Element {
  const fieldsStates = fields.map((field) => React.useState<string>(field.defaultValue ?? ''));

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>('');

  const [invalidFields, setInvalidFields] = React.useState<Record<string, string | boolean>>({});

  const [subscribe, setSubscribe] = React.useState<boolean>(false);

  const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(null);

  interface SubmitEvent extends Event {
    persist(): void;
  }

  const onFormSubmit = (event: unknown) => {
    const submitEvent = event as SubmitEvent;
    submitEvent.preventDefault();

    const emailData: EmailDataType = {};
    const formData: ExtendedFormDataType = fields.reduce<FormDataType>((result, field, index) => {
      if (field.name === 'EMAIL') {
        [emailData.EMAIL] = fieldsStates[index];
      }
      const value = fieldsStates[index][0];
      result[field.name] = value;
      return result;
    }, {
      CATEGORY: category,
      SUBSCRIBE: String(subscribe),
    });
    if (selectedFiles) {
      formData.attachments = selectedFiles;
    }

    setErrorMessage('');
    setInvalidFields({});
    setLoading(true);

    onSubmit(formData)
      .then(() => {
        setLoading(false);
      })
      .catch((error: RequestFormError) => {
        setLoading(false);
        if (error.message) {
          const elements: React.ReactChild[] = [];
          let lastIndex = 0;
          const globalEmailRegExp = new RegExp(emailRegExp, 'g');
          let match = globalEmailRegExp.exec(error.message);
          let matchIndex = 0;
          while (match !== null) {
            // Push preceding text if there is any
            if (match.index > lastIndex) {
              elements.push(error.message.substring(lastIndex, match.index));
            }
            const email = match[0];
            elements.push(<a key={matchIndex} href={`mailto:${email}`}>{email}</a>);

            matchIndex += 1;
            lastIndex = match.index + email.length;
            match = globalEmailRegExp.exec(error.message);
          }
          if (error.message.length > lastIndex) {
            elements.push(error.message.substring(lastIndex));
          }

          setErrorMessage(<>{elements}</>);
        }
        else {
          setErrorMessage(error.message);
        }
        if (error.invalidFields) {
          setInvalidFields(error.invalidFields);
        }

        const anchor = document.getElementById('requestFormSection');
        if (anchor) {
          if (Object.keys(error.invalidFields || {}).length > 0) {
            const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
            window.scroll({
              top: offsetTop - 96,
              behavior: 'smooth',
            });
          }
          else {
            anchor.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }
        }
      });
  };

  const defineFileProperties = (object: File): void => {
    Object.defineProperty(object, 'name', { value: object.name, enumerable: true });
    Object.defineProperty(object, 'type', { value: object.type, enumerable: true });
    Object.defineProperty(object, 'size', { value: object.size, enumerable: true });
    Object.defineProperty(object, 'lastModified', { value: object.lastModified, enumerable: true });
  };

  const checkFilesToIdentity = (file1: File, file2: File): boolean => {
    defineFileProperties(file1);
    defineFileProperties(file2);
    const values = Object.values(file1);
    const values2 = Object.values(file2);
    return values.every((value, index) => value === values2[index]);
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const target = event.target as HTMLInputElement;
    const filesMaximum = ContactUsForm.maxAttachmentsCount;

    if (!target.files?.length) {
      return;
    }

    const files: FileList = (target.files as FileList);
    let filesAsArray = Array.from(files);
    if (selectedFiles && selectedFiles.length) {
      const additionalFiles = filesAsArray.filter((file) => !selectedFiles.some((selectedFile) => checkFilesToIdentity(selectedFile, file)));
      const totalFilesLength = selectedFiles.length + additionalFiles.length;
      if (totalFilesLength <= filesMaximum) {
        filesAsArray = selectedFiles.concat(additionalFiles);
      }
      else {
        filesAsArray = selectedFiles.concat(additionalFiles.slice(0, filesMaximum - selectedFiles.length));
        setErrorMessage(`You can't upload more than ${filesMaximum} files. Please reduce the quantity of the files or add them to an archive and try again.`);
      }
    }
    else {
      filesAsArray = filesAsArray.slice(0, filesMaximum);
      if (target.files.length > filesMaximum) {
        setErrorMessage(`You can't upload more than ${filesMaximum} files. Please reduce the quantity of the files or add them to an archive and try again.`);
      }
    }

    const fileSize = filesAsArray.reduce((size, file) => size + file.size, 0); // bytes
    if (fileSize <= ContactUsForm.fileSizeLimit) {
      setSelectedFiles(filesAsArray);
    }
    else {
      setErrorMessage(`You can't upload more than ${ContactUsForm.fileSizeLimitMB} MB. Please reduce the size of the files and try again.`);
    }

    target.value = '';
  };

  const fileDelete = (name: string) => {
    if (selectedFiles) {
      const files = selectedFiles.slice();
      const i = files.findIndex((file) => file.name === name);
      if (i !== -1) {
        files.splice(i, 1);
        setSelectedFiles(files);
      }
    }
  };

  return (
    <section id="requestFormSection" className={className}>
      <div className="container">
        <div className="row no-gutters justify-content-center mx-md-5 mx-xl-8">
          <div className={`col-12 ${fields.length <= 2 ? 'col-md-9 col-lg-7 col-xxl-6' : ''} card card-border card-border-xl border-${color} shadow`}>
            <div className="card-body px-md-6 py-6">
              <form onSubmit={onFormSubmit} className="row" encType="multipart/form-data">
                <div className="col-12 col-md-6 order-md-last d-flex justify-content-end align-items-center font-size-sm">
                  <RequiredIcon />&nbsp;required fields
                </div>
                {fields.map((field, index) => {
                  let children;
                  const fieldLabel = FormLabels[field.name];
                  const fieldPlaceHolder = FormPlaceholders[field.name];
                  const fieldType = FormItemType[field.name];
                  if (fieldType === 'select') {
                    if (!field.options || field.options.length === 0) {
                      throw new Error(`Error: item "${field.name}" has 'select' type, but has no options!`);
                    }
                    if (!field.options.find((option) => option.value === '' && option.label === 'Select one' && option.hidden === true)) {
                      field.options.unshift({ value: '', label: 'Select one', hidden: true });
                    }
                  }
                  return (
                    <FormControl
                      key={field.name}
                      id={field.name}
                      type={fieldType}
                      label={fieldLabel}
                      placeholder={fieldPlaceHolder}
                      required={field.required}
                      className={`col-12 mb-6 ${(fieldType !== 'textarea' && fields.length > 2) ? 'col-md-6' : ''}`}
                      name={field.name}
                      value={fieldsStates[index][0]}
                      setValue={(value) => {
                        invalidFields[field.name] = false;
                        fieldsStates[index][1](value);
                      }}
                      hasAttachments={hasAttachments}
                      invalidMessage={invalidFields[field.name]}
                      options={field.options}
                    >
                      {children}
                    </FormControl>
                  );
                })}

                {hasAttachments && (
                  <div className="col-12 mt-n5">
                    <div className="form-group d-flex justify-content-between flex-column flex-sm-row">
                      <label htmlFor="attachment" className="d-inline-block file-attachment">
                        <input type="file" id="attachment" multiple hidden onChange={handleFiles} />
                        <AttachmentIcon className="mw-md-100 d-inline-block mt-n1 mr-3" />
                        <span className="d-inline-block">Add attachments</span>
                      </label>
                      <div className="d-flex align-items-center mt-n1 mb-sm-1 mb-4">
                        <InformationIcon />
                        <span className="ml-2" style={{ fontSize: '12px' }}>Max. {ContactUsForm.maxAttachmentsCount} files. Max. upload size: {ContactUsForm.fileSizeLimitMB} MB</span>
                      </div>
                    </div>
                  </div>
                )}

                {selectedFiles && (
                  <div className="col-12 mb-6 mt-n4">
                    {selectedFiles.map((file) => (
                      <span key={file.name} className="btn btn-outline d-inline-flex mr-1 mb-1" style={{ alignContent: 'center', textAlign: 'center', fontSize: '14px', lineHeight: '19px', padding: '8px 12px', background: 'rgba(217, 226, 239, 0.2)', border: '1px solid rgb(217, 226, 239)' }}>
                        <div className="mt-1 mb-n1">{file.name}</div>
                        <button type="button" className="close" onClick={() => fileDelete(file.name)}>
                          <span className="ml-2">&times;</span>
                        </button>
                      </span>
                    ))}
                  </div>
                )}

                {notificationCheck && (
                  <div className="col-12">
                    <div className="form-group notification-group mb-7 font-size-sm ml-n1">
                      <p className="ml-2 mb-2">To ensure that you will get all important communications from us, please tick the check box below.</p>
                      <GreenCheckboxWithLabel id="notificationCheck" onChange={() => setSubscribe(!subscribe)} className="pl-0 ml-6 text-black">
                        Send me news and information on CAD Exchanger products. <Link to="/legal/">Check out our privacy policy</Link>.
                      </GreenCheckboxWithLabel>
                      <p className="ml-2">You may unsubscribe from these communications at any time by hitting the &quot;unsubscribe&quot; link in the footer of our emails.</p>
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className={clsx('btn btn-primary py-2 px-6', loading && 'loading')}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
              {errorMessage && (
                <div className="invalid-feedback d-block mt-4">{errorMessage}</div>
              )}

              {ccLink && (
                <p className="mb-0 mt-6 text-black" style={{ minHeight: 0 }}>Already have an account? <Link to="https://my.cadexchanger.com/">Sign in</Link></p>
              )}

              {suggestCall && (
                <div className={ccLink ? 'mt-4' : 'mt-6'}>
                  <p className="text-black mb-0">Have a question or need a recommendation?</p>
                  <LinkWithIcon
                    to="https://meetings.hubspot.com/cadex/sales-meeting"
                    className="d-inline-block py-2 pr-2"
                    icon={PhoneIcon}
                  >
                    Schedule a call with us
                  </LinkWithIcon>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}
